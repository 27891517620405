<template>
  <dash-page-new
      :side-navigation="$store.state.discount.navigation"
      :title="$store.state.discount.pageData.title || $t('DiscountSystem')"
      :icon="$store.state.discount.pageData.icon || 'mdi-sale'"
      navigation-route="discount"

  >

    <template #header_action.desktop>
      <v-btn v-if="$store.state.discount.pageData.headerAction"
             @click="$store.state.discount.pageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             dark>
        {{ $store.state.discount.pageData.headerActionText }}
      </v-btn>
    </template>

    <template #header_action.mobile>
      <v-btn v-if="$store.state.discount.pageData.headerAction"
             @click="$store.state.discount.pageData.headerAction()" :color="wsATTENTION" dark>
        <v-icon>
          {{ $store.state.discount.pageData.headerActionIcon || 'mdi-save' }}
        </v-icon>
      </v-btn>
    </template>

    <template #default>
      <router-view></router-view>
    </template>
  </dash-page-new>
</template>

<script>
export default {
  name: "BusinessDiscount",
  computed : {
  navigationItems() {
    return [
      { text : this.$t('Discounts')      , value : 'permanent'  , path : '' , icon : "mdi-sale" },
      { text : this.$t('PromoCodes')     , value : 'promo'      , path : 'promo'     , icon : "mdi-gift-outline" },
 //     { text : this.$t('LoyaltySystem')  , value : 'loyalty'    , path : 'loyalty'   , icon : "mdi-card-account-details-star-outline" },
    ]
  }
},
mounted() {
  this.$store.state.discount.navigation = this.navigationItems
},
beforeDestroy() {
  this.$store.state.discount.pageData = {}
  this.$store.state.discount.filtersData = {}
}
}
</script>

<style scoped>

</style>